<template>
  <van-dialog
    v-model="visible"
    show-cancel-button
    confirm-button-color="#055C9D"
    confirm-button-text="提交"
    cancel-button-text="取消"
    cancel-button-color="#4B4E51"
    className="vant-dialog-confirm-blue"
    :beforeClose="onBeforeClose"
    close-on-click-overlay
  >
    <div class="dialog-content">
      <div class="title">验货结论</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="result">
          <el-radio-group v-model="form.result">
            <el-radio
              v-for="item in Object.keys(ARTICLE_CONCLUSION_DICT)"
              :key="item"
              :label="`${item}`"
            >
              {{ ARTICLE_CONCLUSION_DICT[item] }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="reason">
          <el-input
            v-if="false"
            v-model="form.reason"
            placeholder="请输入理由"
            type="textarea"
            :rows="3"
            :maxlength="100"
          />
        </el-form-item>
      </el-form>
    </div>
  </van-dialog>
</template>

<script>
import {ARTICLE_CONCLUSION_DICT} from '@/constants'

export default {
  name: "CheckResultDialog",
  data() {
    return {
      visible: false,
      form: {
        result: '',
        reason: '',
      },
      rules: {
        result: {required: true, message: "请选择验货结论"}
      },
      ARTICLE_CONCLUSION_DICT
    }
  },
  methods: {
    open() {
      this.visible = true
      this.form.result = ''
      this.form.reason = ''
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    onBeforeClose(action, down) {
      if (action === 'confirm') {
        this.$refs.form.validate(valid => {
          if (valid) {
            down(false)
            this.$emit('on-ok', this.form)
          } else {
            down(false)
          }
        })
      } else {
        down()
      }
    },
  },
}
</script>

<style scoped lang="less">
.dialog-content {
  padding: 32px 24px 0;

  .title {
    font-weight: 700;
    font-size: 16px;
    color: #4B4E51;
    text-align: center;
    line-height: 16px;
  }

  ::v-deep {
    .el-radio-group {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .el-radio {
        margin-right: 17px;

        .el-radio__label {
          padding-left: 0;
          font-size: 16px;
          color: #797C80;
          font-weight: 400;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .el-textarea__inner {
      background: #FFFFFF;
      border: 1px solid #F1F2F6;
      border-radius: 4px;
    }
  }
}
</style>
